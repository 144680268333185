class Helper {

    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

}


class StateChecker
{
    #id = 0;

    getID()
    {
        return this.#id;
    }
    constructor()
    {
        this.#id = Helper.getRandomInt(10000);
    }

    isSame(otherState)
    {
        return this.#id === otherState.getID();
    }
}

class Tones {
    static toneOscillatorContext = new (window.AudioContext || window.webkitAudioContext)();

    static generateClearAlert()
    {
        this.generateTone(910, 100);
    }
    static generatePTT(clearAlert)
    {
        let beepLengthShort = 30;
        let beepLengthMid = 50;
        let waitShort = 20;
        let beepLong = 150;
        let waitLong = 80;
        this.generateToneAtSpecificTime(910,beepLengthShort);
        this.generateToneAtSpecificTime(910,beepLengthShort,beepLengthShort+waitShort);
        this.generateToneAtSpecificTime(910,beepLengthMid,beepLengthShort+waitShort+beepLengthShort+waitShort);
        if(clearAlert)
            this.generateToneAtSpecificTime(910,100,beepLengthShort+waitShort+beepLengthShort+waitShort+beepLengthMid+waitLong);
    };


    static generateBoop(length)
    {
        if(length===undefined)
        {
            length = 150
        }
        this.generateTone(910, length,);
    };

    static generateBeep(length)
    {
        if(length===undefined)
        {
            length = 250
        }
        this.generateTone(910, length,);
    };

    static generateBonk(length = 250)
    {
        return this.generateTone(400,length);
    };

    static playAlertTone(toneType)
    {
        switch (toneType)
        {
            case "A":
                this.generateTone(900,3000);
                break;
            case "B":
                this.generateTone(900,250,()=>{
                    setTimeout(()=>{
                        this.generateTone(900,250,()=>{
                            setTimeout(()=>{
                                this.generateTone(900,250,()=>{
                                    setTimeout(()=>{
                                        this.generateTone(900,250,()=>{
                                            setTimeout(()=>{
                                                this.generateTone(900,250,()=>{
                                                })
                                            },250)
                                        })
                                    },250)
                                })
                            },250)
                        })
                    },250)
                });
                break;
            case "C":
                this.generateTone(700,250,()=>{
                    this.generateTone(900,250,()=>{
                        this.generateTone(700,250,()=>{
                            this.generateTone(900,250,()=>{
                                this.generateTone(700,250,()=>{
                                    this.generateTone(900,250,()=>{
                                        this.generateTone(700,250,()=>{
                                            this.generateTone(900,250,()=>{
                                                this.generateTone(700,250,()=>{
                                                    this.generateTone(900,250,()=>{

                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
                break;
            case "HOLD":
                this.generateTone(700,500,()=>{
                });
        }
    }

    static generateQuikCallPage(tone1, tone2) {
        this.generateTone(this.getQuikCallFrequency(tone1), 1000, () => {
            this.generateTone(this.getQuikCallFrequency(tone2), 3000)
        })
    }

    static getQuikCallFrequency(target) {
        switch (Number(target)) {
            case 110:
                return 330.5;
            case 111:
                return 349;
            case 112:
                return 368.5;
            case 113:
                return 389;
            case 114:
                return 410.8;
            case 115:
                return 433.7;
            case 116:
                return 457.9;
            case 117:
                return 483.5;
            case 118:
                return 510.5;
            case 119:
                return 539;
            case 120:
                return 569.1;
            case 121:
                return 600.9;
            case 122:
                return 634.5;
            case 123:
                return 669.9;
            case 124:
                return 707.3;
            case 125:
                return 746.8;
            case 126:
                return 788.5;
            case 127:
                return 832.5;
            case 128:
                return 879;
            case 129:
                return 928.1;
            case 138:
                return 288.5;
            case 108:
                return 296.5;
            case 139:
                return 304.7;
            case 109:
                return 313.8;
            case 160:
                return 953.7;
            case 130:
                return 979.9;
            case 161:
                return 1006.9;
            case 131:
                return 1034.7;
            case 162:
                return 1063.2;
            case 189:
                return 1092.4;
            case 140:
                return 321.7;
            case 141:
                return 339.6;
            case 142:
                return 358.6;
            case 143:
                return 378.6;
            case 144:
                return 399.8;
            case 145:
                return 422.1;
            case 146:
                return 445.7;
            case 147:
                return 470.5;
            case 148:
                return 496.8;
            case 149:
                return 524.6;
            case 150:
                return 553.9;
            case 151:
                return 584.8;
            case 152:
                return 617.4;
            case 153:
                return 651.9;
            case 154:
                return 688.3;
            case 155:
                return 726.8;
            case 156:
                return 767.4;
            case 157:
                return 810.2;
            case 158:
                return 855.5;
            case 159:
                return 903.2;
            case 190:
                return 1122.5;
            case 191:
                return 1153.4;
            case 192:
                return 1185.2;
            case 193:
                return 1217.8;
            case 194:
                return 1251.4;
            case 195:
                return 1285.8;
            case 196:
                return 1321.2;
            case 197:
                return 1357.6;
            case 198:
                return 1395;
            case 199:
                return 1433.4;
            case 170:
                return 1472.9;
            case 171:
                return 1513.5;
            case 172:
                return 1555.2;
            case 173:
                return 1598;
            case 174:
                return 1642;
            case 175:
                return 1687.2;
            case 176:
                return 1733.7;
            case 177:
                return 1781.5;
            case 178:
                return 1830.5;
            case 179:
                return 1881;
            case 200:
                return 1930.2;
            case 201:
                return 1989;
            case 202:
                return 2043.8;
            case 203:
                return 2094.5;
            case 204:
                return 2155.6;
            case 205:
                return 2212.2;
            case 206:
                return 2271.7;
            case 207:
                return 2334.6;
            case 208:
                return 2401;
            case 209:
                return 2468.2;
        }
    }

    static generateToneAtSpecificTime(freq,length,delay=0)
    {
        length = length/1000;
        delay = delay/1000;
        let osc = this.toneOscillatorContext.createOscillator();
        let vol = this.toneOscillatorContext.createGain();
        vol.gain.value = 0.1;
        if(freq!=0) {
            osc.type = 'sine'; // this is the default - also square, sawtooth, triangle
            osc.frequency.value = freq; // Hz
            osc.connect(vol); // connect it to the destination
            vol.connect(this.toneOscillatorContext.destination);
            console.debug("schedule tone of "+freq+"hz")
            console.debug("at "+this.toneOscillatorContext.currentTime+delay)
            console.debug("to end at "+(this.toneOscillatorContext.currentTime+length-delay))
            osc.start(this.toneOscillatorContext.currentTime+delay);
            osc.stop(((this.toneOscillatorContext.currentTime+length)+delay));
        }
    }

    static toneStopper;

    static generateTone(freq,length,callback)
    {
        let osc = this.toneOscillatorContext.createOscillator();
        let vol = this.toneOscillatorContext.createGain();
        vol.gain.value = 0.1;
        if(freq!=0) {
            osc.type = 'sine'; // this is the default - also square, sawtooth, triangle
            osc.frequency.value = freq; // Hz
            osc.connect(vol); // connect it to the destination
            vol.connect(this.toneOscillatorContext.destination);
            osc.start();
            this.toneStopper=osc.stop;
            if(length==0)
            {
                let stop=false;
                setInterval(function(){
                    if(stop)
                    {
                        osc.stop();
                        return false;
                    }
                },100);
                return function(){
                    stop=true;
                };
            }
        }
        setTimeout(function(){
            if(freq!=0)
                osc.stop();
            if(callback)
            {
                callback();
            }
        },length)
    }
}

class OpCodes
{
    static CCUPDATE = 0x001;
    static REGISTER = 0x110;
    static DEREGISTER = 0x111;
    static REGISTRATION_OK = 0x210;
    static REGISTRATION_DENIED = 0x211;
    static REGISTRATION_FAILED = 0x212;
    static DEREGISTER_ANNOUNCE = 0x213;
    static AFFILIATE = 0x120;
    static DEAFFILIATE = 0x121;
    static DEAFFILIATE_ANNOUNCE = 0x222;
    static AFFILIATE_OK = 0x220;
    static AFFILIATE_DENIED = 0x221;
    static EMERGENCY = 0x131;
    static EMERGENCY_CLEAR = 0x132;
    static EMERGENCY_FORCE_CLEAR = 0x135;
    static CHANNEL_REQ = 0x141;
    static CHANNEL_REL = 0x142;
    static CHANNEL_GRANT = 0x241;
    static CHANNEL_DENY = 0x242;
    static CHANNEL_ANNOUNCE = 0x243;
    static CHANNEL_REL_ANNOUNCE = 0x244;
    static REMOTE_INHIBIT = 0x250;
    static REMOTE_UNINHIBIT = 0x251;
    static REMOTE_PING = 0x280;
    static REMOTE_PING_RESPONSE = 0x180;
    static QUERY_LOCATION = 0x1001;
    static QUERY_RESPONSE = 0x1901
}

class CAIDataPacket
{
    messageID
    subscriber
    target
    payload
    messageKey

    /**
     * @param {number} opcode
     * @param {number} subscriber
     * @param {number} target
     * @param {object} payload
     * @param {number} messageKey
     */
    constructor(opcode,subscriber,target,payload,messageKey)
    {
        this.messageID = opcode;
        this.subscriber = subscriber;
        this.target = target;
        this.payload = payload;
        this.messageKey = messageKey;
    }
}

class CAIDataObject
{
    packet = undefined;
    key = undefined;

    /**
     * @param {string} key
     * @param {CAIDataPacket} packet
     */
    constructor(key,packet) {
        this.packet = packet;
        this.key = key;
    }

    sendTo(socket)
    {
        socket.emit("CAIData",this);
    }
}


class WebRadio {
    metadataCallback = undefined;
    #currentChannelState = new StateChecker();
    #currentUI = {
        line1 : "",
        line2: "",
        statusLED: "off"
    }
    #handleData = false;
    #subscriberID = 0;
    #websocket = undefined;
    #systemID = undefined;
    #affiliationState = -1;
    #talkgroup = 0;
    #voice = undefined;
    #eventCB = undefined;
    #channelReqCallback = undefined;
    #securekey = false;
    #rxActive = false;
    #broadcastingTones = false
    #channelHold = false
    #channelHoldState = new StateChecker();

    /**
     * @param {number} subscriberID
     * @param {Socket} websocket
     * @param {string} systemID
     */
    constructor(subscriberID,websocket,systemID)
    {
        console.log(`Init webradio ID ${subscriberID}`)
        this.#systemID = systemID;
        this.#subscriberID = subscriberID;
        this.#websocket = websocket;
        this.#handleData = true;
        this.#websocket.on("systemTone",data => {
            this.#handleTone(data).then(r => {});
        })
        this.#websocket.on("CAIData",data => {
            this.#handleCAIData(data)
        })
        this.#voice = new Voice(this.#websocket);
        this.#voice.setSubscriber(subscriberID);
        this.#voice.cbVoiceMetadata = (data)=>{
            if(this.metadataCallback!==undefined&&this.metadataCallback!==null)
            {
                this.metadataCallback(data)
            }
        }
        this.#runPingThread().then();
    }

    async setChannelHold(state) {
        console.info(`Channel hold state set to ${state}`)
        this.channelHold = state;
        let chstate = this.#channelHoldState = new StateChecker();
        if(state === true)
        {
            while(this.channelHold===true && chstate.isSame(this.#channelHoldState)) {
                console.debug("Begin channel hold loop")
                while (this.localPTTState === true || this.#rxActive === true) {
                    console.debug("Check for free channel...")
                    let waited = false;
                    console.debug("Check local PTT state:",this.localPTTState)
                    while (this.localPTTState === true) {
                        console.debug("Wait for Tx clear")
                        waited = true;
                        await Helper.sleep(250);
                    }
                    console.debug("Check rx active:",this.#rxActive)
                    while (this.#rxActive === true)
                    {
                        console.debug("Wait for Rx clear, current channel = " + this.#voice.currentChannel);
                        waited = true;
                        await Helper.sleep(250);
                    }
                    if (waited) {
                        console.debug("Wait to give other users a chance...")
                        await Helper.sleep(1000);
                    }
                    await Helper.sleep(1);

                }
                console.debug("Broadcast hold...")
                this?.#eventCB({"id": "tx", status: true})
                await this.broadcastTone("HOLD", 0, 0);
                this?.#eventCB({"id": "tx", status: false})
                await Helper.sleep(10000)
            }
        }
    }

    setAudioDevice(device) {
        this.#voice.setAudioDevice(device);
    }


    getQuikCallFrequency(target) {
        switch (Number(target)) {
            case 110:
                return 330.5;
            case 111:
                return 349;
            case 112:
                return 368.5;
            case 113:
                return 389;
            case 114:
                return 410.8;
            case 115:
                return 433.7;
            case 116:
                return 457.9;
            case 117:
                return 483.5;
            case 118:
                return 510.5;
            case 119:
                return 539;
            case 120:
                return 569.1;
            case 121:
                return 600.9;
            case 122:
                return 634.5;
            case 123:
                return 669.9;
            case 124:
                return 707.3;
            case 125:
                return 746.8;
            case 126:
                return 788.5;
            case 127:
                return 832.5;
            case 128:
                return 879;
            case 129:
                return 928.1;
            case 138:
                return 288.5;
            case 108:
                return 296.5;
            case 139:
                return 304.7;
            case 109:
                return 313.8;
            case 160:
                return 953.7;
            case 130:
                return 979.9;
            case 161:
                return 1006.9;
            case 131:
                return 1034.7;
            case 162:
                return 1063.2;
            case 189:
                return 1092.4;
            case 140:
                return 321.7;
            case 141:
                return 339.6;
            case 142:
                return 358.6;
            case 143:
                return 378.6;
            case 144:
                return 399.8;
            case 145:
                return 422.1;
            case 146:
                return 445.7;
            case 147:
                return 470.5;
            case 148:
                return 496.8;
            case 149:
                return 524.6;
            case 150:
                return 553.9;
            case 151:
                return 584.8;
            case 152:
                return 617.4;
            case 153:
                return 651.9;
            case 154:
                return 688.3;
            case 155:
                return 726.8;
            case 156:
                return 767.4;
            case 157:
                return 810.2;
            case 158:
                return 855.5;
            case 159:
                return 903.2;
            case 190:
                return 1122.5;
            case 191:
                return 1153.4;
            case 192:
                return 1185.2;
            case 193:
                return 1217.8;
            case 194:
                return 1251.4;
            case 195:
                return 1285.8;
            case 196:
                return 1321.2;
            case 197:
                return 1357.6;
            case 198:
                return 1395;
            case 199:
                return 1433.4;
            case 170:
                return 1472.9;
            case 171:
                return 1513.5;
            case 172:
                return 1555.2;
            case 173:
                return 1598;
            case 174:
                return 1642;
            case 175:
                return 1687.2;
            case 176:
                return 1733.7;
            case 177:
                return 1781.5;
            case 178:
                return 1830.5;
            case 179:
                return 1881;
            case 200:
                return 1930.2;
            case 201:
                return 1989;
            case 202:
                return 2043.8;
            case 203:
                return 2094.5;
            case 204:
                return 2155.6;
            case 205:
                return 2212.2;
            case 206:
                return 2271.7;
            case 207:
                return 2334.6;
            case 208:
                return 2401;
            case 209:
                return 2468.2;
        }
    }

    generateQuikCallPage = (tone1, tone2) => {
        Tones.generateTone(this.getQuikCallFrequency(tone1), 1000, () => {
            Tones.generateTone(this.getQuikCallFrequency(tone2), 3000)
        })
    }

    async broadcastAlertTone(type)
    {
        console.debug("Broadcast tone "+type);
        Tones.playAlertTone(type)
        this.#websocket.emit('systemTone',{
            key:this.#systemID,
            talkgroup: this.#talkgroup,
            subscriber: this.#subscriberID,
            tone1: "",
            tone2: "",
            type: type
        })
        if(type === "HOLD")
        {
            await Helper.sleep(1000);
        }
        else
            await Helper.sleep(4000);
    }

    async broadcastQCIITone(tone1, tone2, type)
    {
        console.debug("Broadcast QCII "+tone1+" "+tone2);
        this.generateQuikCallPage(tone1,tone2);

        this.#websocket.emit('systemTone',{
            key:this.#systemID,
            talkgroup: this.#talkgroup,
            subscriber: this.#subscriberID,
            tone1: tone1,
            tone2: tone2,
            type: type
        })
        await Helper.sleep(4000);
    }

    async broadcastTone(type,tone1="",tone2="")
    {
        try {
            this.#broadcastingTones = true;
            console.info("Broadcast tone type " + type);
            let result = undefined;
            console.debug("Requesting channel");
            this.sendPacket(new CAIDataPacket(OpCodes.CHANNEL_REQ, this.#subscriberID, this.#talkgroup, this.#talkgroup, Helper.getRandomInt(1000)))
            this.#channelReqCallback = (packet) => {
                console.debug("Got callback");
                console.debug("Result: ",packet);
                result = packet;
            }
            let counter = 0;
            while (result === undefined && (counter += 1 <= 10000)) {
                await Helper.sleep(1);
            }
            console.debug("Result: ",result);
            if (result.messageID === OpCodes.CHANNEL_GRANT) {
                console.debug("Channel Grant successful, broadcasting tone");
                if (type === "QCII") {
                    await this.broadcastQCIITone(tone1, tone2, type);
                } else {
                    await this.broadcastAlertTone(type);
                }
                this.sendPacket(new CAIDataPacket(OpCodes.CHANNEL_REL,this.#subscriberID,0,this.#talkgroup,Helper.getRandomInt(1000)))
            } else {
                console.error("Channel Grant failed");
                Tones.generateBonk();
            }
        }
        catch(e)
        {

        }
        finally{
            this.#broadcastingTones = false;
        }

    }

    setEventCallback(cb)
    {
        this.#eventCB = cb;
    }

    deregister()
    {
        this.#handleData = false;
        // this.sendPacket(new CAIDataPacket(OpCodes.DEREGISTER,this.#subscriberID,0,0,0));
        // For multiradio, don't actually deregister the SU to prevent issues with other consoles
        // Instead, just ignore any additional data directed at this one
        this.#affiliationState = -1;
    }

    register()
    {

        this.#handleData = true;
        this.sendPacket(new CAIDataPacket(OpCodes.REGISTER,this.#subscriberID,0,0,Helper.getRandomInt(1000)));
    }

    delete()
    {
        // stop handling CAI data
        this.#systemID = "";
    }

    async #runPingThread()
    {
        while(true)
        {
            console.debug("Run ping thread...")
            if(this.#affiliationState!==-1)
            {
                console.debug("Radio registered, activating ping");
                this.sendPacket(new CAIDataPacket(OpCodes.REMOTE_PING_RESPONSE,this.#subscriberID,0,0,0));
            }
            await Helper.sleep(10000);
        }
    }

    async #handleTone(tone)
    {
        if(!this.#handleData || this.#broadcastingTones)
        {
            return;
        }
        if(tone.key === this.#systemID && tone.talkgroup === this.#talkgroup)
        {
            if (tone.type === "QCII") {
                await this.generateQuikCallPage(tone.tone1, tone.tone2);
            } else {
                await Tones.playAlertTone(tone.type);
            }
        }
    }

    /**
     * @param {CAIDataObject} CAIDataObject
     */
    #handleCAIData(CAIDataObject)
    {
        if(!this.#handleData)
        {
            return;
        }
        if(CAIDataObject.key === this.#systemID)
        {
            console.debug(`Got CAIData ${JSON.stringify(CAIDataObject)}`);
            let packet = CAIDataObject.packet;
            switch (packet.messageID)
            {
                case OpCodes.REGISTRATION_OK:
                    if(packet.target === this.#subscriberID)
                    {
                        console.debug(`Subscriber ${this.#subscriberID} reg OK`)
                        this.#affiliationState = 0;
                    }
                    break;
                case OpCodes.AFFILIATE_OK:
                    if(packet.target === this.#subscriberID)
                    {
                        console.debug(`Subscriber ${this.#subscriberID} aff OK to ${packet.payload}`)
                        this.#talkgroup = packet.payload;
                        this.#affiliationState = packet.payload;
                    }
                    break;
                case OpCodes.AFFILIATE_DENIED:
                    if(packet.target === this.#subscriberID)
                    {
                        this.sendPacket(new CAIDataPacket(OpCodes.REGISTER,this.#subscriberID,0,0,Helper.getRandomInt(1000)))
                        console.debug(`Subscriber ${this.#subscriberID} aff DENIED to ${packet.payload}`)
                        this.#affiliationState = -1;
                    }
                    break;
                case OpCodes.EMERGENCY:
                    if(packet.payload.toString() === this.#talkgroup.toString())
                    {
                        if(this.#eventCB!==undefined)
                        {
                            // noinspection JSValidateTypes
                            this.#eventCB({id:"emerg","subscriber":packet.subscriber})
                        }
                    }
                    break;
                case OpCodes.EMERGENCY_CLEAR:
                    if(packet.payload.toString() === this.#talkgroup.toString())
                    {
                        if(this.#eventCB!==undefined)
                        {
                            // noinspection JSValidateTypes
                            this.#eventCB({id:"emerg_clear"})
                        }
                    }
                    break;
                case OpCodes.EMERGENCY_FORCE_CLEAR:
                    if(packet.target.toString() === this.#talkgroup.toString())
                    {
                        if(this.#eventCB!==undefined)
                        {
                            // noinspection JSValidateTypes
                            this.#eventCB({id:"emerg_clear"})
                        }
                    }
                    break;
                case OpCodes.CHANNEL_ANNOUNCE:
                    if(!this.localPTTState&&packet.messageKey.toString() === this.#talkgroup.toString())
                    {
                        this.#currentChannelState = new StateChecker();
                        if(this.#securekey!==undefined && this.#securekey!==null)
                        {
                            this.#voice.setVoiceChannel(this.#systemID+":"+packet.payload+"$"+this.#securekey)
                            this.#rxActive = true;
                        }
                        else
                        {
                            this.#voice.setVoiceChannel(this.#systemID+":"+packet.payload)
                            this.#rxActive = true;
                        }
                        if(this.#eventCB!==undefined)
                        {
                            // noinspection JSValidateTypes
                            this.#eventCB({id:"rx","subscriber":packet.target})
                        }
                    }
                    break;
                case OpCodes.CHANNEL_REL_ANNOUNCE:
                    if(packet.messageKey.toString() === this.#talkgroup.toString())
                    {
                        let releaseState = this.#currentChannelState = new StateChecker();
                        setTimeout(()=>{
                            if(releaseState.isSame(this.#currentChannelState)) {
                                this.#voice.leaveVoiceChannel()
                                this.#rxActive = false;
                                if (this.#eventCB !== undefined) {
                                    // noinspection JSValidateTypes
                                    this.#eventCB({id: "release"})
                                }
                            }
                        },2000)

                    }
                    break;
                case OpCodes.CHANNEL_GRANT:
                case OpCodes.CHANNEL_DENY:
                    if(this.#channelReqCallback!=undefined)
                    {
                        this.#channelReqCallback(packet);
                    }
            }
        }
    }

    sendPacket(CAIDataPacket)
    {
        console.debug(`Send CAIData ${JSON.stringify(CAIDataPacket)}`);
        this.#websocket.emit("CAIData",new CAIDataObject(this.#systemID,CAIDataPacket));
    }

    async #affiliateTalkgroup(talkgroupID,key = null)
    {
        this.#securekey = key;
        if(!this.#handleData)
        {
            this.#handleData = true;
        }
        if(this.#affiliationState===-1)
        {
            console.log("Registering to system...")
            this.sendPacket(new CAIDataPacket(OpCodes.REGISTER,this.#subscriberID,0,0,Helper.getRandomInt(1000)))
        }
        while(this.#affiliationState===-1)
        {
            await Helper.sleep(1);
        }
        this.sendPacket(new CAIDataPacket(OpCodes.AFFILIATE,this.#subscriberID,0,talkgroupID,0))
        while(this.#talkgroup!==talkgroupID)
        {
            await Helper.sleep(1);
        }
    }

    async setTalkgroup(talkgroupID,key = null)
    {
        if(talkgroupID === 0)
        {
            this.sendPacket(new CAIDataPacket(OpCodes.DEAFFILIATE,this.#subscriberID,0,talkgroupID,0))
            await Helper.sleep(100)
        }
        else
        {
            await this.#affiliateTalkgroup(talkgroupID,key)
        }
        this.#talkgroup = talkgroupID;
    }

    getTalkgroup()
    {
        return this.#talkgroup;
    }

    getSubscriberID()
    {
        return this.#subscriberID;
    }


    localPTTState = false;

    async downPTT()
    {
        if(!this.localPTTState)
        {
            this.localPTTState = true;
            if(this.#affiliationState === this.#talkgroup)
            {
                let result = undefined;
                // noinspection DuplicatedCode
                let messageKey = Helper.getRandomInt(1000);
                this.sendPacket(new CAIDataPacket(OpCodes.CHANNEL_REQ,this.#subscriberID,0,this.#talkgroup,messageKey))
                this.#channelReqCallback = (packet) => {
                    console.debug("Got response packet",packet)
                    if(packet.messageKey === messageKey) {
                        console.log("Got response to PTT request",packet)
                        result = packet;
                    }
                }
                let counter = 0;
                while(result===undefined&&(counter+=1<=10000))
                {
                    await Helper.sleep(1);
                }
                if(result.messageID===OpCodes.CHANNEL_GRANT)
                {
                    if(this.#securekey!==undefined && this.#securekey!==null)
                    {
                        this.#voice.setVoiceChannel(this.#systemID+":"+result.payload+"$"+this.#securekey)
                    }
                    else
                    {
                        this.#voice.setVoiceChannel(this.#systemID+":"+result.payload)
                    }
                    this.#voice.setPTT(true);
                    Tones.generatePTT(true);
                    return true;
                }
                else
                {
                    Tones.generateBonk();
                    return false;
                }
            }
            else
            {
                return false;
            }
        }
        else
        {
            return;
        }
    }

    async upPTT()
    {
        if(this.localPTTState)
        {
            this.localPTTState = false;
            this.sendPacket(new CAIDataPacket(OpCodes.CHANNEL_REL,this.#subscriberID,0,this.#talkgroup,Helper.getRandomInt(1000)))
            this.#voice.setPTT(false);
        }
        else
        {
            return;
        }
    }
}