const LOCAL = false;

async function getServerForKey(key)
{
    console.log("Getting server for key")
    try {
        if(LOCAL)
        {
            return "http://localhost:8080"
        }
        let response = await axios.get("https://director.scncomms.app/api/getZoneController", {
            params: {
                key: key
            }
        })
        return `https://${response.data}`;
    }
    catch(e)
    {
        console.error(`Error occurred while attempting to get server info. Error: [${e}]`)
    }
}

let globalSocket = undefined;
let socketAddr = "main-1.cc.p25.scncomms.app";
let systemKey = "E826968B5607D38E021E4254D39A9017";
let webRadioID = 27000;
let configChannels = [
    {id: 27101,name: "27 RDC DISP"},
    {id: 27102,name: "27 RDC RCRD"},
    {id: 27111,name: "27 RDC TAC 1"},
    {id: 27112,name: "27 RDC TAC 2"},
    {id: 27701,name: "27 FIRE DISP"}
];
let configTones = [
    {
        type:"QCII",
        tone1:112,
        tone2:154,
        name:"27 ST12",
        tgID:27701
    },
    {
        type:"QCII",
        tone1:138,
        tone2:161,
        name:"27 ST13",
        tgID:27701
    },
    {
        type:"QCII",
        tone1:159,
        tone2:201,
        name:"27 ST43",
        tgID:27701
    },
    {
        type:"QCII",
        tone1:156,
        tone2:193,
        name:"27 ST44",
        tgID:27701
    }
]

let channelTones = [
    {
        toneIcon: "SOLID",
        toneText: "ATTN",
        toneID: "A"
    },
    {
        toneIcon: "INTERVAL",
        toneText: "ALRT",
        toneID: "B"
    },
    {
        toneIcon: "WAVERING",
        toneText: "EVAC",
        toneID: "C"
    }
]
let loadingDiag = $.dialog({
    lazyOpen: true,
    title: 'Loading...',
    content: 'Loading Configuration...',
    type: 'blue',
    infinite: true
});

let loginDiag = $.dialog({
    lazyOpen: true,
    title: 'Login Required',
    content: 'Login is required to load this console. Please allow pop-ups and log in to continue.',
    type: 'orange'
});

let loginFailedDiag = $.dialog({
    lazyOpen: true,
    title: 'Login Failed',
    content: 'Login to this panel failed',
    type: 'red'
});
let configGroups = [];
function addScript( src ) {
    let s = document.createElement( 'script' );
    s.setAttribute( 'src', src );
    document.body.appendChild( s );
}


async function waitForLogin()
{
    loadingDiag.close();
    loginDiag.open();
    window.open("https://login.scncomms.app/login");
    let user = undefined;
    let awaitLogin = async function()
    {
        return new Promise(innerResolve => {
            var interval = setInterval(async ()=>{
                let updatedUser = undefined;
                try{
                    updatedUser = (await axios.get("https://login.scncomms.app/api/user/",{
                        method:"get",
                        withCredentials:true
                    })).data;
                }
                catch(e)
                {

                }
                if(updatedUser!==undefined) {
                    clearInterval(interval);
                    innerResolve(updatedUser);
                }
            },1000)
        })
    }
    user = await awaitLogin();
    loginDiag.close();
    return user;
}

async function getSecuredConsoleConfiguration()
{
    try {
        let user = undefined;
        try {
            user = await axios.get("https://login.scncomms.app/api/user/", {method: "get", withCredentials: true});
        } catch (e) {

        }
        if (user == undefined) {
            user = await waitForLogin();
        }
        let handshakeCode = (await axios.get("https://api.gen2.radio.scncomms.app/api/secure/login/init", {
            method: "get",
            withCredentials: true
        })).data;
        let responseCode = (await axios.get(`https://login.scncomms.app/api/submitVerifyReq/${handshakeCode}`, {
            method: "get",
            withCredentials: true
        })).data;
        let finalizedLogin = (await axios.get(`https://api.gen2.radio.scncomms.app/api/secure/login/verify?code=${responseCode}`, {
            method: "get",
            withCredentials: true
        })).data;
        let config = (await axios.get(`https://api.gen2.radio.scncomms.app/api/secure/config?viewKey=${new URLSearchParams(window.location.search).get('view')}`, {
            method: "get",
            withCredentials: true
        })).data;
        return config;
    }
    catch(e)
    {
        console.error("Error while logging in",e);
        loginFailedDiag.open();
    }
}

async function getConfiguration()
{
    $("#console").hide();
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    const desktop = urlParams.get('desktop');
    if (desktop !== null) {
        addScript("./dist/desktop.js")
    }

    if(view!=null)
    {
        let result = await $.ajax(`https://scnp25-desktop-autoconfig.scncomms.app/view/${view}.json`);
        if(result.shadow == true) {
            result = await getSecuredConsoleConfiguration();
        }
        console.log(result);
        configChannels = result.channels;
        configTones = result.tones;
        if(result.groups!=undefined)
        {
            configGroups = result.groups;
        }
        webRadioID = result.sysID;
        systemKey = result.key;
        socketAddr = await getServerForKey(systemKey);
        console.log(`Got socketaddr: ${socketAddr}`)
        if(result.socketAddr!=undefined)
        {
            socketAddr = result.socketAddr;
        }
    }
    $("#console").show();
    setTimeout(()=>{
        $('.tabs').tabs();
    },500)
}

function getToneForEvent(e)
{
    return getToneForID(e.currentTarget.parentElement.dataset.tgId,e.currentTarget.parentElement.dataset.type,e.currentTarget.parentElement.dataset.tone1,e.currentTarget.parentElement.dataset.tone2)
}

function getToneForID(tgID,type,tone1,tone2)
{
    for (const t of webconsole.tones)
    {
        if (t.tgID === tgID && t.type === type && t.tone1 === tone1 && t.tone2 === tone2)
        {
            return t;
        }
    }
}



class Tone {
    group;
    name;
    tgID;
    type;
    tone1;
    tone2;
    pagingRadio;
    that;
    queued;
    tx;
    playSingleTone = async () =>
    {
        this.tx = true;
        try {
            await this.pagingRadio.setTalkgroup(this.tgID);
            await this.pagingRadio.broadcastTone(this.type, this.tone1, this.tone2);
            await this.pagingRadio.setTalkgroup(0);
        }
        catch(e) {
            console.log("Error while playing tone")
            console.log(e)
        }
        this.tx = false;
    }

    playTone = async () => {
        webconsole.tonesPlaying = true;
        for (const item of webconsole.tones) {
            if(item.queued || (item.tgID === this.tgID && item.type === this.type && item.tone1 === this.tone1 && item.tone2 === this.tone2))
            {
                item.queued = false;
                await Helper.sleep(1)
                await item.playSingleTone();
                await Helper.sleep(500);
            }
        }
        webconsole.tonesPlaying = false;
    }

    addToMultipage = () => {
        this.queued = !this.queued;
    }

    constructor(name,tgID,type,tone1,tone2,group,pagingRadio)
    {
        this.that = this;
        this.name = name;
        this.type = type;
        this.tgID = tgID;
        this.tone1 = tone1;
        this.tone2 = tone2;
        this.group = group;
        this.pagingRadio = pagingRadio;
        this.queued = false;
        this.tx = false;
    }
}

class Channel {
    currentAlias;
    currentSubscriber;
    radioID;
    encryptionkey;
    group;
    tgID;
    name;
    key;
    status;
    monitored;
    tx;
    rx;
    emg;
    webRadio;
    toneText;
    toneID;
    toneIcon;
    held;
    expanded;
    callList;
    constructor(tgID,name,key,group,encryptionkey,radioID,expanded) {
        this.radioID=radioID;
        this.encryptionkey=encryptionkey;
        this.group=group;
        this.tgID=tgID;
        this.name=name;
        this.key=key;
        this.status= "OFFLINE";
        this.monitored= false;
        this.tx=false;
        this.rx=false;
        this.emg=0;
        this.webRadio=undefined;
        this.toneText="ATTN";
        this.toneID="A";
        this.toneIcon="SOLID";
        this.held=false;
        this.expanded=expanded;
        this.callList=[{"radioID":"","type":""},{"radioID":"","type":""},{"radioID":"","type":""},{"radioID":"","type":""},{"radioID":"","type":""},{"radioID":"","type":""},{"radioID":"","type":""},{"radioID":"","type":""},{"radioID":"","type":""},{"radioID":"","type":""}];
    }

    addToCallList(radioID,type)
    {
        this.callList.unshift({
            radioID: radioID,
            type:type
        })
        while(this.callList.count>10)
        {
            this.callList.pop();
        }
    }

    eventCallback = async (eventData) =>{
        if(eventData.id === "rx")
        {
            this.currentSubscriber = eventData.subscriber;
            this.rx = true;
            this.addToCallList(eventData.subscriber,"Voice Call")
            while(this.rx === true)
            {
                if(this.currentAlias===undefined) {
                    this.status = `Rx ${eventData.subscriber}`;
                }
                else
                {
                    this.status = `Rx ${this.currentAlias}`;
                }
                await Helper.sleep(200);
            }
        }
        else if(eventData.id === "tx")
        {
            if(eventData.status === true)
            {
                this.status = `Tx`;
            }
            else
            {
                this.status = "ONLINE";
            }
            this.tx = eventData.status;
        }
        else if(eventData.id === "release")
        {
            this.rx = false;
            if(this.currentAlias===undefined) {
                this.status = `LC ${this.currentSubscriber}`;
            }
            else
            {
                this.status = `LC ${this.currentAlias}`;
            }
            this.currentAlias = undefined;
            this.currentSubscriber = undefined;
        }
        else if(eventData.id === "emerg")
        {
            webconsole.emergencyMuted = false;
            this.emg = eventData.subscriber;
            this.addToCallList(eventData.subscriber,"Emergency Alarm")
            while(this.emg === eventData.subscriber)
            {
                this.status = `Emergency ${eventData.subscriber}`;
                await Helper.sleep(5000);
                this.status = "ONLINE";
                await Helper.sleep(5000);
            }
        }
        else if(eventData.id === "emerg_clear")
        {
            this.emg = 0;
            this.status = `ONLINE`;
        }
        webconsole.forceUpdate();
    };
    toggleMonitor = async (e)=>{
        try {
            let radioID = this.radioID;
            console.log(`Toggle monitor ${this.tgID} rid ${this.radioID}`);
            this.monitored=!this.monitored;
            if(this.monitored)
            {
                this.webRadio = new WebRadio(this.radioID,globalSocket,this.key);
                this.webRadio.setEventCallback(this.eventCallback);
                this.webRadio.metadataCallback = (metadata) => {
                    if(metadata!==undefined)
                    {
                        if(metadata.alias!==undefined && metadata.alias !== null)
                        {
                            this.currentAlias = metadata.alias;
                            console.trace(`Got alias ${this.currentAlias}`)
                        }
                    }
                }

                this.status = "CONNECTED, AFFILIATING";
                await this.webRadio.setTalkgroup(this.tgID,this.encryptionkey);
                this.status = "ONLINE";
                refreshChannelAudio();
            }
            else
            {
                this.webRadio.deregister();
                this.webRadio.delete();
                this.webRadio = undefined;
                this.status = "OFFLINE";
            }
        }
        catch(e)
        {
            console.warn("Error toggling monitor");
            console.warn(e);
        }
    };
    playToneWebPanel= async (e,talkgroup,key)=> {
        console.log(`Broadcast tone on ${talkgroup} : ${this.toneID}`)
        await this.webRadio.broadcastTone(this.toneID, null, null);
    };
    setToneWebPanel=(e,talkgroup,key)=>
    {
        this.toneIcon = e.target.dataset.toneIcon;
        this.toneText = e.target.dataset.toneText;
        this.toneID = e.target.dataset.toneId;
        console.log(`Set tone on ${talkgroup} to ${this.toneID}`)
    };
    clearEmergency=(e)=>
    {
        let talkgroup = e.currentTarget.parentElement.dataset.tgId;
        let key = e.currentTarget.parentElement.dataset.key;
        console.log(`Clear emergency on ${talkgroup}`);
        let forceClear = new CAIDataPacket(OpCodes.EMERGENCY_CLEAR,this.emg, 0,this.webRadio.getTalkgroup(),0);
        this.webRadio.sendPacket(forceClear);
        this.webRadio.sendPacket(forceClear);
        this.emg = 0;
    };
    togglePrimary=(e)=>
    {
        try {
            let radioID = this.radioID;
            console.log(`Toggle primary to ${radioID}`);
            webconsole.primary = radioID;
        }
        catch(e)
        {
            console.warn("Error toggling PTT");
            console.warn(e);
        }
    };
    togglePTT=async (e)=>{
        try {
            let talkgroup = this.tgID;
            let key = this.key;
            console.log(`Toggle PTT ${talkgroup}`);
            this.ptt = !this.ptt;
            if (this.ptt) {
                let result = await this.webRadio.downPTT();
                if(result === true)
                {
                    this.status = "Tx";
                    this.tx = true;
                }
            } else {
                this.ptt=false;
                await this.webRadio.upPTT();
                this.tx=false;
                this.status = "ONLINE";
            }
        }
        catch(e)
        {
            console.warn("Error toggling PTT");
            console.warn(e);
        }
    };
    toggleHold=(e)=>{
        try{
            if(!this.ptt) {
                setTimeout(() => {
                    this?.eventCallback({id: "tx", status: !this.hold})
                }, 1)
            }

            setTimeout(()=> {
                console.info("Toggling channel hold " + !this.hold)
                this.hold = !this.hold;
                this.webRadio.setChannelHold(this.hold);
                webconsole.forceUpdate();
            }, 50);

            if(!this.ptt) {
                setTimeout(() => {
                    this?.eventCallback({id: "tx", status: false})
                }, 1000)
            }
        }
        catch(e)
        {
            console.warn("Error toggling channel hold");
            console.warn(e);
        }
    }
    setTone=(e)=>
    {
        this.setToneWebPanel(e,this.tgID,this.key)
    };
    broadcastTone=async (e)=>
    {
        await this.playToneWebPanel(e,this.tgID,this.key)
    }
}


function getTone(name, tgID, type, tone1, tone2, group, pagingRadio) {
    return new Tone(name, tgID, type, tone1, tone2, group, pagingRadio)
}

function getChannel(tgID,name,key,group,encryptionkey,radioID,expanded=false)
{
    return new Channel(tgID,name,key,group,encryptionkey,radioID,expanded);
}

let webconsole;


async function getPatches()
{
    await globalSocket.emit('getPatches',systemKey,(status,result)=>{
        if(status === "OK")
        {
            webconsole.patches = result;
            Promise.resolve();
        }
    })
}

async function showPatchPanel()
{
    console.log("Showing patch panel")
    console.log("Active patches: "+JSON.stringify(webconsole.patches))
}

function refreshChannelAudio()
{
    if(webconsole.config.unselectAudioSeperate)
    {
        let index = 0;
        for (const c of webconsole.channels) {
            if(index === webconsole.primary)
            {
                // primary
                if(c.webRadio !== undefined)
                {
                    if(webconsole.config.primarySpeaker!==null)
                    {
                        c.webRadio.setAudioDevice(undefined)
                    }
                    else {
                        c.webRadio.setAudioDevice(webconsole.config.primarySpeaker)
                    }
                }
            }
            else
            {
                // secondary
                if(c.webRadio !== undefined)
                {
                    if(webconsole.config.unselectSpeaker!==null)
                    {
                        c.webRadio.setAudioDevice(undefined)
                    }
                    else {
                        c.webRadio.setAudioDevice(webconsole.config.unselectSpeaker)
                    }
                }
            }
        }
    }
    else
    {
        console.debug(webconsole.config.primarySpeaker)
        for (const c of webconsole.channels) {
            if(c.webRadio !== undefined)
            {
                if(webconsole.config.primarySpeaker===null)
                {
                    c.webRadio.setAudioDevice(null)
                }
                else {
                    c.webRadio.setAudioDevice(webconsole.config.primarySpeaker)
                }
            }
        }
    }
}



var toningRadio;

async function initialSetup()
{
    loadingDiag.open();
    await getConfiguration();

    globalSocket = io(socketAddr);
    await Helper.sleep(100);
    let currentID = webRadioID;
    toningRadio = new WebRadio(currentID+=1,globalSocket,systemKey)
    toningRadio.register();
    let tones = [];
    if(configTones!==undefined)
    {
        for (let configTone of configTones) {
            tones.push(getTone(configTone.name,configTone.tgID,configTone.type,configTone.tone1,configTone.tone2,configTone.group,toningRadio))
        }
    }
    let channels = [];
    for (let configChannel of configChannels) {
        channels.push(getChannel(configChannel.id,configChannel.name,configChannel.key??systemKey,configChannel.group,configChannel.encryptionkey,currentID+=1,configChannel.expanded));
    }

    let groups = configGroups;

    let audioDevices = await navigator.mediaDevices.enumerateDevices();

    webconsole = new Vue({
        el: '#console',
        data: {
            desktopShortcut:-1,
            shortcutNextEntry:false,
            desktop:false,
            groups: groups,
            primary:-1,
            channels: channels,
            channelTones: channelTones,
            tones:tones,
            tonesPlaying:false,
            patches: undefined,
            emergencyMuted:false,
            settingsActive:undefined,
            audioDevicesHolder:audioDevices,
            config:{
                unselectAudioSeperate:false,
                unselectSpeaker:null,
                unselectVol:70,
                primarySpeaker:null,
                primaryVol:100
            }
        },
        methods: {
            forceUpdate:function(){
                this.$forceUpdate();
            },
            primaryPtt:function(state) {
                let primaryIndex = -1;
                for (let channelsKey in this.channels) {
                    if(this.channels[channelsKey].radioID==this.primary)
                    {
                        primaryIndex = channelsKey;
                    }
                }
                if(primaryIndex === -1)
                {
                    return;
                }

                if(!this.channels[primaryIndex].ptt)
                {
                    if(state===true)
                    {
                        this.channels[primaryIndex].togglePTT()
                    }
                }
                else
                {
                    if(state!==true)
                    {
                        this.channels[primaryIndex].togglePTT()
                    }
                }
            },
            clickConfigurePatches: async function(event) {
                await getPatches();
                await showPatchPanel();
            },
            emgMute:function(){
                this.emergencyMuted = true;
            },
            showLog:function(){
                desktopAPI.showLog();
            },
            showRadios:function(){
                desktopAPI.showRadios();
            },
            setPrimary:async function(event){
                console.debug(event.target);
                let targetID = event.target.dataset.id;
                console.debug(`Set primary device to ${targetID}`)
                if(targetID==="default")
                {
                    return;
                }
                for (const d of this.audioDevicesHolder) {
                    console.debug(`compare [${d.deviceId}] to [${targetID}]`)
                    if(d.deviceId === targetID)
                    {
                        webconsole.config.primarySpeaker = d;
                        break;
                    }
                }
                refreshChannelAudio();
            },
            setUnselect:async function(event){
                let targetID = event.target.dataset.id;
                console.debug(`Set unselect device to ${targetID}`)
                if(targetID==="default")
                {
                    return;
                }
                for (const d of this.audioDevicesHolder) {
                    console.debug(`compare [${d.deviceId}] to [${targetID}]`)
                    if(d.deviceId === targetID)
                    {
                        webconsole.config.unselectSpeaker = d;
                        break;
                    }
                }
                refreshChannelAudio();
            },
            settings:async function(){
                if(this.settingsActive===undefined)
                {
                    var elems = document.querySelectorAll('#settingsModal');
                    var instances = M.Modal.init(elems, {
                        onCloseEnd: ()=>{
                            webconsole.settingsActive = false;
                        }
                    });
                    this.settingsActive=false;
                }
                let $settings = $("#settingsModal")
                if(this.settingsActive)
                {
                    $settings.modal('close');
                    $('.dropdown-trigger').dropdown();
                    this.settingsActive = false;
                }
                else
                {
                    $settings.modal('open');
                    this.settingsActive = true;
                }
            },
            setDesktopShortcut:async function(){
                desktopAPI.sendAlert("Keyboard Entry","Click out of the application then press the key to use for the global PTT shortcut");
                let nextKey = true;
                api.receive("keyevent",(e,type)=>{
                    if(nextKey && type==="down")
                    {
                        localStorage.setItem("desktopshortcut",e.keycode);
                        this.desktopShortcut = e.keycode;
                        nextKey = false;
                        desktopAPI.sendAlert("Keyboard Entry","Global keyboard shortcut set successfully.");
                    }
                })
            }
        }
    })

    if(localStorage.key("desktopshortcut"))
    {
        webconsole.desktopShortcut = localStorage.getItem("desktopshortcut")
    }


    setTimeout(()=>{
        $('.dropdown-trigger').dropdown();
    },500)

    await Helper.sleep(500);
    loadingDiag.close();

    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    if(view==="tutorial")
    {
        introJs().onbeforechange(async (target, step, c, d) => {
            console.log("target",target);
            console.log("step",step);
            console.log("c",c);
            console.log("d",d);
            if(step === 5)
            {
                console.log("Enabling resource...")
                if(!webconsole.channels[0].enabled)
                {
                    await webconsole.channels[0].toggleMonitor(null);
                }
            }
            if(step===6)
            {
                emergencyAudio.volume = 0.1;
                webconsole.channels[0].eventCallback({
                    id:"emerg",
                    subscriber:1234
                })
            }
            if(step===8)
            {
                webconsole.emergencyMuted = true;
            }
            if(step===9)
            {
                emergencyAudio.volume = 0.25;
                webconsole.channels[0].clearEmergency({
                    currentTarget:target
                });
            }
            if(step===12)
            {
                document.querySelector("#console > div:nth-child(1) > nav > div.nav-content.red.darken-3 > ul > li:nth-child(2) > a").click();
            }
        }).setOptions({
            hidePrev: true,
            disableInteraction: true,
            exitOnEsc: false,
            exitOnOverlayClick: false,
            skipLabel: "",
            steps:
                [
                    {
                        intro: "Welcome to the SCNP25 Dispatch Panel! This tutorial will show you how to use the panel."
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div'),
                        intro: "This is a radio resource. This is how you interact with the radio system from the dispatch panel."
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div > button.btn.mon-button.grey'),
                        intro: "This button enables or disables a radio resource. If the button is green, the resource is enabled. If the button is grey, the resource is disabled. Disabled resources will not play audio and will not receive emergency alarms."
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div > button.btn.ptt-button'),
                        intro: "This button toggles PTT (Push-to-talk) for this radio resource."
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div > button.btn.hold-button.disabled'),
                        intro: "This button toggles a channel hold tone which plays every 10 seconds."
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div > button.btn.pri-button.disabled'),
                        intro: "This button selects this resource as the primary selected resource. When in desktop mode, the primary selected resource will be activated when the PTT keybind is used."
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div'),
                        intro: "When an emergency alarm is received, the resource will flash red/orange and play an audible alarm tone."
                    },
                    {
                        element: document.querySelector('#console > div:nth-child(1) > nav > div.nav-wrapper > div > ul > li:nth-child(1) > a'),
                        intro: "This button silences any active emergency alarms without knocking them down. The resource will remain flashing and other dispatchers will still hear the alarm."
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div > button.btn.emerg-button'),
                        intro: "This button clears (knocks down) an active emergency on this resource. Knocking down emergency alarms deactivates emergency alarm on the source radio and on all other users on the channel."
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div > button.btn.attn-button'),
                        intro: "This button allows you to play a tone on this radio resource"
                    },
                    {
                        element: document.querySelector('#group-0 > div:nth-child(1) > div > div > a'),
                        intro: "This button allows you to select the tone to play with the tone button"
                    },
                    {
                        element: document.querySelector('#console > div:nth-child(1) > nav > div.nav-content.red.darken-3 > ul'),
                        intro: "These tabs (if present) allow you to move between resource groups"
                    },
                    {
                        element: document.querySelector('#group-1 > div:nth-child(5) > div'),
                        intro: "This is a paging resource. Paging resources are used to send two-tone pages to users on the radio system."
                    },
                    {
                        element: document.querySelector('#group-1 > div:nth-child(5) > div > div > button:nth-child(3)'),
                        intro: "This button sends the page"
                    },
                    {
                        element: document.querySelector('#group-1 > div:nth-child(5) > div > div > button:nth-child(4)'),
                        intro: "This button adds the page to a multi-page group. Multi-page groups allow you to send multiple pages at once. Once the pages are added, you can send them by pressing the page button on any of them."
                    },
                    {
                        intro: "You may try features on this test console to learn how they work - it's not connected to your live console and won't affect anyone else. Enjoy!"
                    },
                ]
        }).start();
    }


    try {
        if (desktop) {
            desktopAPI.sendNotification("Loaded", "Dispatch panel load complete")
        }
    }
    catch(e)
    {

    }
}
let emergencyAudio = new Audio('audio/mcc7550_emerg.wav');
emergencyAudio.loop = true;

let emergActive = false

setInterval(()=>{
    let emergencyActive = false
    for(let channel of webconsole.channels)
    {
        if(channel.emg!=0)
        {
            emergencyActive = true
        }
    }
    if(emergencyActive && !emergActive && !webconsole.emergencyMuted)
    {
        emergencyAudio.play()
        emergActive = true;
    }
    else if(!emergencyActive && emergActive || webconsole.emergencyMuted)
    {
        emergencyAudio.pause();
        emergActive = false;
    }
},500)

initialSetup();

window.addEventListener('keydown',(e)=>{
    console.log(e);
})